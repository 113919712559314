<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoBox',
  props: {
    iconCode: String as PropType<string>,
    iconUri: String as PropType<string>,
    text: String as PropType<string>,
    border: Boolean as PropType<boolean>,
    bold: Boolean as PropType<boolean>,
    bg: Boolean as PropType<boolean>,
  },
})
</script>

<template>
  <div class="flex" :class="{ 'rounded-md bg-gray-100 md:bg-white md:px0 py-1 px0.5 ': bg }">
    <div :class="{ 'border-b-1 border-gray-200 p-b-2': border }" class="w-full">
      <div class="flex items-center gap-x-1 sm:gap-x-3">
        <template v-if="iconUri">
          <img :src="iconUri" class="block h-6 w-6 flex-none text-black" :alt="text">
        </template>
        <template v-else-if="iconCode">
          <NIcon :icon="iconCode" class="h-6 w-6 flex-none" />
        </template>
        <span :class="{ 'font-semibold': bold }" class="block text-xs sm:text-sm">{{ text }}</span>
      </div>
    </div>
  </div>
</template>
